import { SET_CV_LINK, SET_NEW_LANGUAGE } from "./constants";

const arm = {
    about: 'ԻՄ ՄԱՍԻՆ',
    aboutMe: 'Իմ մասին',
    aboutMeText: `Բարև Ձեզ!\nԻմ անունը Արման Ղարիբյան է: Ես ֆուլ-ստեկ և մոբայլ ծրագրավորող եմ. Ես ունեմ ավելի քան ${new Date().getFullYear() - 2018} տարվա ծրագրավորման փորձ: Ես ճշտապահ ու պատասխանատու աշխատող եմ:`,
    arm: 'Հայ',
    armenian: 'Հայերեն',
    basic: 'Հիմանական',
    cat: 'Կատ',
    contacts: 'ՀԵՏԵԴԱՐՁ ԿԱՊ',
    contactsTitle: 'Հետադարձ կապ',
    downloadCV: 'Ներբեռնել ՍՎ',
    education:'Կրթություն',
    educationText1: 'Քանաքեռավանի միջնակարգ Դպրոց, Հայաստան (2000-2010)',
    educationText2: 'Հայաստանի Պետական Պոլիտեխնիկական Համալսարանի Ինֆորմացիոն Տեխնոլոգիաների ֆակուլտետ (2010-2014)',
    eng: 'Անգ',
    english: 'Անգլերեն',
    from2017: '2017թ․-ի մարտից մինջև 2018թ․-ի հուլիս ամիսը աշխատել եմ IT Innovations ընկերությունում, որպես ջունիոր ֆրոնտ-էնդ ծրագրավորող։',
    from2017Info1: 'Վեբ հավելվածներ և դրանվ առանձին մասերի ստեղծում և սպասարկում',
    from2017Info2: 'Վեբ կայքերի կատարելագործում html, css, javascript և jquery օգտագործման միջոցով',
    from2019: '2019 թվականի հունվարից մինչև 2021 թվականի նոյեմբեր աշխատել է ֆրիլանսերում:',
    from2019Info1: 'React կոմպոնենտների ստեղծում և ինտեգրում',
    from2019Info2: 'React-ով գրված վեբ հավելվածների արդիականցում և փոփոխում',
    from2019Info3: 'Node js սերվերների ստեղծեւմ, փոփոխում և արդիականացում',
    from2019Info4: 'Մոբայլ հավելվածնեի ստեղծում',
    from2021: '2021 թվականի նոյեմբերից մինչ այժմ աշխատում եմ Mercanis-ում',
    from2021Info1: 'Software engineer',
    languages: 'Լեզուներ',
    loading: 'Բեռնում',
    myReactNativeApp: 'Իմ react-native մոբայլ հավելվածը Google Play-ում',
    myUnityGame: 'Իմ Unity մոբայլ խաղը Google Play-ում', 
    name: 'Արման Ղարիբյան',
    native: 'Մայրենի',
    portfolio: 'ՊՈՐՏՖՈԼԻՈ',
    portfolioTitle: 'Պորտֆոլիո',
    profession: 'ՖՈՒԼ-ՍՏԵԿ ԾՐԱԳՐԱՎՈՐՈՂ, ՄՈԲԱՅԼ ԾՐԱԳՐԱՎՈՐՈՂ',
    experience :'ՓՈՐՁ',
    experienceTitle: 'Փորձ',
    rus: 'Ռուս',
    russian: 'Ռուսերեն',
    skills: 'ՀՄՏՈՒԹՅՈՒՆԵՐ',
    skillsTitle: 'Հմտություներ',
    sp: 'Իսպ',
    spanish: 'Իսպաներեն',
    veryGood: 'Շատ լավ',
    website: 'վեբ կայք'
}

const cat = {
    about: 'SOBRE MI',
    aboutMe: 'Sobre mi',
    aboutMeText: `Hola!\nEm dic Arman Gharibyan. Sóc desenvolupador full-stack i mòbil. Tinc més de ${new Date().getFullYear() - 2018} anys d'experiència en desenvolupament. Sóc un empleat puntual i responsable.`,
    arm: 'Arm',
    armenian: 'Armeni',
    basic: 'Bàsic',
    cat: 'Cat',
    contacts: 'CONTACTES',
    contactsTitle: 'Contactes',
    downloadCV: 'Descarregar CV',
    education: 'Educació',
    educationText1: 'Batxillerat d’Qanaqeravan, Armeni (2000-2010)',
    educationText2: 'Facultat de Tecnologies de la Informació de la Universitat Politècnica Nacional d’Armènia(2010-2014)',
    eng: 'Ang',
    english: 'Anglès',
    from2017: 'Des del març del 2017 fins al juliol del 2018 va treballar a IT Innovations com a desenvolupador de frontend junior.',
    from2017Info1: 'Desenvolupament d\'aplicacions web i les seves parts independents amb React i manteniment.',
    from2017Info2: 'Millora de pàgines web mitjançant html, css, javascript i jquery.',
    from2019: 'Des de gener de 2019 fins a novembre de 2021 va treballar en freelance.',
    from2019Info1: 'Desenvolupament i integració de components React',
    from2019Info2: 'Modifiqueu l\'aplicació web React existent',
    from2019Info3: 'Desenvolupament o modificació de servidors js de node',
    from2019Info4: 'Desenvolupament o modificació d\'aplicacions mòbils',
    from2021: 'Des del novembre de 2021 fins ara treballo a Mercanis',
    from2021Info1: 'Enginyer de software',
    languages: 'Idiomes',
    loading: 'S\'està carregant',
    myReactNativeApp: 'La meva aplicació mòbil react-native a Google Play',
    myUnityGame: 'La meva joc mòbil Unity a Google Play', 
    name: 'Arman Gharibyan',
    native: 'Nadiu',
    portfolio: 'PORTAFOLI',
    portfolioTitle: 'Portafoli',
    profession: 'DESENVOLUPADOR FULL STACK, DESENVOLUPADOR MOBILE',
    experience :'EXPERIÈNCIA',
    experienceTitle: 'Experiència',
    rus: 'Rus',
    russian: 'Ruso',
    skills: 'HABILIDADES',
    skillsTitle: 'Habilidades',
    sp: 'Esp',
    spanish: 'Español',
    veryGood: 'Avançat',
    website: 'sitio web'
}

const eng = {
    about: 'ABOUT',
    aboutMe: 'About me',
    aboutMeText: `Hello!\nMy name is Arman Gharibyan. I am a full-stack and mobile developer. I have more ${new Date().getFullYear() - 2018} years of experience in development. I am a punctual and responsible employee.`,
    arm: 'Arm',
    armenian: 'Armenian',
    basic: 'Basic',
    cat: 'Cat',
    contacts: 'CONTACTS',
    contactsTitle: 'Contacts',
    downloadCV: 'Download CV',
    education:'Education',
    educationText1: 'High school of Qanaqeravan, Armenia (2000-2010)',
    educationText2: 'Faculty of Information Technologies at National Polytechnic University of Armenia(2010-2014)',
    eng: 'Eng',
    english: 'English',
    from2017: 'From March 2017 to July 2018 worked in IT Innovations as a junior frontend developer.',
    from2017Info1: 'Development of web applications and its independent parts with React and maintenance.',
    from2017Info2: 'Improvement of web pages using html, css, javascript and jquery.',
    from2019: 'From january  2019 to november 2021 worked in freelance',
    from2019Info1: 'Development and integration of React components',
    from2019Info2: 'Modify the existing React web application',
    from2019Info3: 'Development or modification of node js servers',
    from2019Info4: 'Development or modification of mobile applications',
    from2021: 'From the november 2021 to now work in Mercanis',
    from2021Info1: 'Software engineer',
    languages: 'Languages',
    loading: 'Loading',
    myReactNativeApp: 'My react-native mobile app on Google Play',
    myUnityGame: 'My Unity mobile game on Google Play', 
    name: 'Arman Gharibyan',
    native: 'Native',
    portfolio: 'PORTFOLIO',
    portfolioTitle: 'Portfolio',
    profession: 'FULL STACK DEVELOPER, MOBILE DEVELOPER',
    experience :'EXPERIENCE',
    experienceTitle: 'Experience',
    rus: 'Rus',
    russian: 'Russian',
    skills: 'SKILLS',
    skillsTitle: 'Skills',
    sp: 'Sp',
    spanish: 'Spanish',
    veryGood: 'Advanced',
    website: 'website'
}

const rus = {
    about: 'ОБО МНЕ',
    aboutMe: 'Обо мне',
    aboutMeText: `Привет!\nМеня зовут Арман Гарибян. Я full-stack и мобильный разработчик. Имею опыт разработки более ${new Date().getFullYear() - 2018}-х лет. Я пунктуальный и ответственный сотрудник.`,
    arm: 'Арм',
    armenian: 'Армянский',
    basic: 'Базовый',
    cat: 'Кат',
    contacts: 'КОНТАКТЫ',
    contactsTitle: 'Контакты',
    downloadCV: 'Скачать резюме',
    education:'Образование',
    educationText1: 'Старшая школа Канакераван, Армения (2000-2010)',
    educationText2: 'Факультет информационных технологий Национального Политехнического Университета Армении(2010-2014)',
    eng: 'Анг',
    english: 'Английский',
    from2017: 'С марта 2017 по июль 2018 работал в IT Innovations младшим фронтенд-разработчиком.',
    from2017Info1: 'Разработка веб-приложений и его независимых частей на React и сопровождение.',
    from2017Info2: 'Улучшение веб-страниц с использованием html, css, javascript и jquery.',
    from2019: 'С января 2019 года по ноябрь 2021 года работал на фрилансе',
    from2019Info1: 'Разработка и интеграция компонентов React',
    from2019Info2: 'Изменения существующих веб-приложение React',
    from2019Info3: 'Разработка или модификация серверов node js',
    from2019Info4: 'Разработка или модификация мобильных приложений',
    from2021: 'С ноября 2021 года по настоящее время работаю в Mercanis',
    from2021Info1: 'Software engineer',
    languages: 'Языки',
    loading: 'Загрузка',
    myReactNativeApp: 'Мое реакт-native мобильное приложение в Google Play',
    myUnityGame: 'Моя мобильная игра Unity в Google Play', 
    name: 'Арман Гарибян',
    native: 'Родной',
    portfolio: 'ПОРТФОЛИО',
    portfolioTitle: 'Портфолио',
    profession: 'Фул-Стек РАЗРАБОТЧИК , МОБИЛЬНЫЙ РАЗРАБОТЧИК',
    experience :'ОПЫТ',
    experienceTitle: 'Опыт',
    rus: 'Ру',
    russian: 'Русский',
    skills: 'НАВЫКИ',
    skillsTitle: 'Навыки',
    sp: 'Исп',
    spanish: 'Испанский',
    veryGood: 'Продвинутый',
    website: 'веб-сайт'
}

const sp = {
    about: 'SOBRE MÍ',
    aboutMe: 'Acerca de mí',
    aboutMeText: `Hola!\nMi nombre es Arman Gharibyan. Soy un desarrollador full-stack y móvil. Tengo más ${new Date().getFullYear() - 2018} años de experiencia en desarrollo. Soy un empleado puntual y responsable.`,
    arm: 'Arm',
    armenian: 'Armenio',
    basic: 'Básico',
    cat: 'Cat',
    contacts: 'CONTACTOS',
    contactsTitle: 'Contactos',
    downloadCV: 'Descargar CV',
    education:'Educación',
    educationText1: 'Bachillerato de Qanaqeravan, Armenia(2000-2010)',
    educationText2: 'Facultad de Tecnologías de la Información de la Universidad Politécnica Nacional de Armenia(2010-2014)',
    eng: 'Ing',
    english: 'Inglés',
    from2017: 'De marzo de 2017 a julio de 2018 trabajaba en IT Innovations como desarrollador de frontend junior.',
    from2017Info1: 'Desarrollo de aplicaciones web y sus partes independientes con React y mantenimiento.',
    from2017Info2: 'Mejora de las páginas web usando html, css, javascript y jquery.',
    from2019: 'De enero 2019 a noviembre 2021 trabajé en freelance',
    from2019Info1: 'Desarrollo y integración de componentes React',
    from2019Info2: 'Modificación la aplicación web React existente',
    from2019Info3: 'Desarrollo o modificación de servidores node js',
    from2019Info4: 'Desarrollo o modificación de aplicaciones móviles',
    from2021: 'Desde noviembre 2021 hasta ahora trabajo en Mercanis',
    from2021Info1: 'Ingeniero de software',
    languages: 'Idiomas',
    loading: 'Cargando',
    myReactNativeApp: 'Mi aplicación móvil react-native en Google Play',
    myUnityGame: 'Mi juego móvil Unity en Google Play', 
    name: 'Arman Gharibyan',
    native: 'Nativo',
    portfolio: 'PORTAFOLIO',
    portfolioTitle: 'portafolio',
    profession: 'DESARROLLADOR FULL STACK, DESARROLLADOR MÓVIL',
    experience :'EXPERIENCIA',
    experienceTitle: 'Experiencia',
    rus: 'Rus',
    russian: 'Ruso',
    skills: 'HABILIDADES',
    skillsTitle: 'Habilidades',
    sp: 'Esp',
    spanish: 'Español',
    veryGood: 'Аvanzadо',
    website: 'sitio web'
}

const initialState = {
    link: 'http://pyunik-main-api.herokuapp.com/api/eng',
    words: {
        about: 'ABOUT',
        aboutMe: 'About me',
        aboutMeText: `Hello!\nMy name is Arman Gharibyan. I am a full-stack and mobile developer. I have more ${new Date().getFullYear() - 2018} years of experience in development. I am a punctual and responsible employee.`,
        arm: 'Arm',
        armenian: 'Armenian',
        basic: 'Basic',
        cat: 'Cat',
        contacts: 'CONTACTS',
        contactsTitle: 'Contacts',
        downloadCV: 'Download CV',
        education:'Education',
        educationText1: 'High school of Qanaqeravan, Armenia (2000-2010)',
        educationText2: 'Faculty of Information Technologies at National Polytechnic University of Armenia(2010-2014)',
        eng: 'Eng',
        english: 'English',
        from2017: 'From March 2017 to July 2018 worked in IT Innovations as a junior frontend developer.',
        from2017Info1: 'Development of web applications and its independent parts with React and maintenance.',
        from2017Info2: 'Improvement of web pages using html, css, javascript and jquery.',
        from2019: 'From january  2019 to november 2021 worked in freelance',
        from2019Info1: 'Development and integration of React components',
        from2019Info2: 'Modify the existing React web application',
        from2019Info3: 'Development or modification of node js servers',
        from2019Info4: 'Development or modification of mobile applications',
        from2021: 'From the november 2021 to now work in Mercanis',
        from2021Info1: 'Software engineer',
        languages: 'Languages',
        loading: 'Loading',
        myReactNativeApp: 'My react-native mobile app on Google Play',
        myUnityGame: 'My Unity mobile game on Google Play', 
        name: 'Arman Gharibyan',
        native: 'Native',
        portfolio: 'PORTFOLIO',
        portfolioTitle: 'Portfolio',
        profession: 'FULL STACK DEVELOPER, MOBILE DEVELOPER',
        experience :'EXPERIENCE',
        experienceTitle: 'Experience',
        rus: 'Rus',
        russian: 'Russian',
        skills: 'SKILLS',
        skillsTitle: 'Skills',
        sp: 'Sp',
        spanish: 'Spanish',
        veryGood: 'Аdvanced',
        website: 'website'
    }
}

const mainReducer = (state = initialState, action) => {
    switch(action.type){
        case SET_NEW_LANGUAGE:
            let langObj = {};
            if(action.lang === 'arm'){
                langObj = {...arm};
            }else if(action.lang === 'cat'){
                langObj = {...cat};
            }else if(action.lang === 'eng'){
                langObj = {...eng};
            }else if(action.lang === 'rus'){
                langObj = {...rus};
            }else if(action.lang === 'sp'){
                langObj = {...sp};
            }
            return{
                ...state,
                words: langObj
            }
        case SET_CV_LINK:
            return{
                ...state,
                link: action.lang
            }
        default: return state;
    }
}

export default mainReducer;