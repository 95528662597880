import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from '../../redux/store';
import InitialCompose from './initial';

const InitialContainer = () => {
    return(
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Provider store={store}>
                  <InitialCompose />
            </Provider>
        </BrowserRouter>
    )
}

export default InitialContainer;